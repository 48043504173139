import { useEffect } from "react";
import { Avatar, Box } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
  openModalShow,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import Filters from "./Filters";
import {
  selectedCreatedFromDateState,
  selectedCreatedToDateState,
  selectedIsViewState,
  selectedPaymentMethodState,
  selectedPaymentStatus,
  selectedStatusState,
} from "../../recoil/select";
import { GetOrderData } from "../../services/api/order";
import icon_master from "../../assets/images/icons/master.png";
import icon_zainCash from "../../assets/images/icons/zain_cash.png";
import icon_fastPay from "../../assets/images/icons/fast_pay.png";
import icon_fib from "../../assets/images/icons/fib.png";
import icon_cash from "../../assets/images/icons/cash.png";
import ModalShow from "./Modal/ModalShow";
import { CheckBox } from "@mui/icons-material";
import moment from "moment";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const [emailSearch, setEmailSearch] = useState(null);
  const [phoneSearch, setPhoneSearch] = useState(null);

  const [selectedStatus, setSelectedStatus] =
    useRecoilState(selectedStatusState);
  const [updateData, setUpdateData] = useRecoilState(updateDataState);
  const state = useRecoilValue(openFilterState);
  const selectedPaymentMethod = useRecoilValue(selectedPaymentMethodState);
  const paymentStatus = useRecoilValue(selectedPaymentStatus);
  const selectedCreatedFromDate = useRecoilValue(selectedCreatedFromDateState);
  const selectedCreatedToDate = useRecoilValue(selectedCreatedToDateState);
  const isView = useRecoilValue(selectedIsViewState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenShowModal = useSetRecoilState(openModalShow);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  function formatDate(inputDate) {
    let formattedDate = `${inputDate.split("T")[0]}`;
    return formattedDate;
  }
  function formatHours(inputDate) {
    let date = new Date(inputDate);
    let hours = date.getHours();
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    let formattedDate = `${hours}:${minutes} ${period}`;
    return formattedDate;
  }

  // replace _ with space and capitalize first letter of each word

  useEffect(() => {
    setDataItem(null);
  }, [state]);
  const handelShow = (data) => {
    setOpenShowModal(true);
    setDataItem(data);
    setMethodRequest("show");
  };
  const handelPaid = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("paid");
  };
  const handelApproved = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("approved");
  };
  const handelRejected = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("rejected");
  };
  const handelReturn = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("return");
  };
  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  const columns = [
    {
      field: "order_number",
      headerName: "OrderID",
      headerClassName: "grid--header",

      minWidth: 140,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.order_number ? params.row?.order_number : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "id",
      headerName: "Order Name",
      headerClassName: "grid--header",

      minWidth: 340,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            {params.row?.order_lines[0] >= 1 && (
              <Avatar
                sx={{
                  borderRadius: 0,
                }}
                sizes="small"
                alt="Image"
                src={params?.row?.images[0]?.image_file}
              />
            )}
            <ColumnText
              text={
                params.row?.order_lines[0]?.product_name
                  ? params.row?.order_lines[0]?.product_name
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "grid--header",

      minWidth: 340,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.created_by.email
                  ? params.row?.created_by.email
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "Qauntity",
      headerName: "Qauntity",
      headerClassName: "grid--header",

      minWidth: 20,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.order_lines.length > 0
                  ? params.row.order_lines.reduce(
                      (total, line) => total + (line.quantity || 0),
                      0
                    )
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "payment_method",
      headerName: "Payment Method",
      headerClassName: "grid--header",

      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.payment_method
                  ? params.row.payment_method
                      .replace(/_/g, " ")
                      .replace(
                        /\w\S*/g,
                        (txt) =>
                          txt.charAt(0).toUpperCase() +
                          txt.slice(1).toLowerCase()
                      )
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "grid--header",

      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.status
                  ? params.row.status
                      .replace(/_/g, " ")
                      .replace(
                        /\w\S*/g,
                        (txt) =>
                          txt.charAt(0).toUpperCase() +
                          txt.slice(1).toLowerCase()
                      )
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
              color={
                params.row?.status === "pending"
                  ? "yellow"
                  : params.row?.status === "approved"
                  ? "#27ae60"
                  : params.row?.status === "rejected"
                  ? "red"
                  : params.row?.status === "returned"
                  ? "#ed1858"
                  : "red"
              }
            />
          </Box>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      headerClassName: "grid--header",

      minWidth: 250,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.created
                  ? moment(new Date(params.row.created)).format("MMM D, h:mm a")
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      minWidth: 280,
      headerClassName: "grid--header",
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnButton
              onClick={() => handelShow(params.row)}
              type={"show"}
            />
            {params.row.payment_method === "cash" &&
            params.row.status !== "canceled" &&
            params.row.payment_status !== "paid" ? (
              <ColumnButton
                onClick={() => handelPaid(params.row)}
                type={"paid"}
              />
            ) : (
              ""
            )}
            {params.row.status !== "canceled" &&
            params.row.payment_status === "paid" &&
            params.row.status !== "approved" &&
            params.row.status !== "returned" &&
            params.row.status !== "rejected" ? (
              <ColumnButton
                onClick={() => handelApproved(params.row)}
                type={"approve"}
              />
            ) : (
              ""
            )}
            {params.row.status !== "canceled" &&
            params.row.status !== "approved" &&
            params.row.status !== "rejected" &&
            params.row.status !== "returned" &&
            params.row.payment_status === "paid" ? (
              <ColumnButton
                onClick={() => handelRejected(params.row)}
                type={"reject"}
              />
            ) : (
              ""
            )}
            {params.row.status === "approved" &&
            params.row.payment_status === "paid" ? (
              <ColumnButton
                onClick={() => handelReturn(params.row)}
                type={"return"}
              />
            ) : (
              ""
            )}
            <ColumnButton
              onClick={() => handelDelete(params.row)}
              type={"delete"}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    GetOrderData(
      data.filters.skip,
      data.filters.take,
      data.filters.search,
      data.filters.order_number,
      selectedStatus,
      selectedPaymentMethod,
      paymentStatus,
      selectedCreatedFromDate,
      selectedCreatedToDate,
      isView
    )
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res.results,
          total: res?.count,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, data: [], loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData]);

  return (
    <>
      <Box
        sx={{
          m: 0,
        }}
      >
        <HeaderGridTabel
          title="Orders"
          icon={
            <HomeWorkIcon
              size={25}
              className="color-primary"
              color={"#ffffff"}
            />
          }
          types={[
            {
              name: "all",
              count: 10,
              function: () => {
                setSelectedStatus("");
                setUpdateData(Math.random());
              },
            },
            {
              name: "approved",
              count: 10,
              function: () => {
                setSelectedStatus("approved");
                setUpdateData(Math.random());
              },
            },
            {
              name: "pending",
              count: 10,
              function: () => {
                setSelectedStatus("pending");
                setUpdateData(Math.random());
              },
            },
            {
              name: "rejected",
              count: 10,
              function: () => {
                setSelectedStatus("rejected");
                setUpdateData(Math.random());
              },
            },
            {
              name: "returned",
              count: 10,
              function: () => {
                setSelectedStatus("returned");
                setUpdateData(Math.random());
              },
            },
          ]}
          fetchData={fetchData}
          isFilter={true}
          data={data?.data}
          exel={true}
        />
        <Box mt={2} sx={{}}>
          <DataGridTabel columns={columns} data={data} setData={setData} />
        </Box>
        {/* Modal */}
        <ModalShow />
        <ModalInfo />
        <ModalDelete />
        {/* Fillter */}
        <Filters
          emailSearch={emailSearch}
          setEmailSearch={setEmailSearch}
          phoneSearch={phoneSearch}
          setPhoneSearch={setPhoneSearch}
          fetchData={fetchData}
        />
      </Box>
    </>
  );
};

export default Tabel;
