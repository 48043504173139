import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { IoIosAddCircleOutline } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";

import { useRecoilState, useSetRecoilState } from "recoil";
import { dataTabelState } from "../../recoil/Tabel";
import { openModalInfo } from "../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
} from "../../recoil/global";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ExportButton from "../Others/ExportButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export const HeaderGridTabel = (props) => {
  const [dataState, setDataState] = useRecoilState(dataTabelState);
  const setOpenAdd = useSetRecoilState(openModalInfo);
  const setDataItem = useSetRecoilState(dataClickItem);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);
  const setOpenFilter = useSetRecoilState(openFilterState);

  // function add
  const handleAdd = () => {
    setOpenAdd(true);
    setDataItem(null);
    setMethodRequest("add");
  };

  return (
    <Card
      elevation={0}
      sx={{
        background: "transparent",
        borderBottom: "1px solid #ffffff5e",
        pb: 2,
      }}
    >
      <CardHeader
        sx={{
          background: "#transparent",
        }}
        title={
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                padding: "0px 10px",
                svg: {
                  fill: "#fff",
                },
              }}
            >
              <Typography
                variant="h6"
                fontWeight="bold"
                px={1}
                mr={1}
                sx={{
                  borderRight: "1px solid #343141",
                }}
                color={"#fff"}
              >
                Dashboard
              </Typography>
              {props.icon}
              <Typography variant="h6" fontWeight="bold" px={2} color={"#fff"}>
                {props.title}
              </Typography>
            </Box>
            {props?.types?.length > 0 && (
              <Box>
                {props.types.map((item, index) => (
                  <Button
                    key={index}
                    variant="text"
                    sx={{
                      backgroundColor: "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={() => {
                      item.function();
                      console.log("item", item);
                    }}
                  >
                    <Typography fontSize={12} color={"rgba(13, 110, 253,1)"}>
                      {item.name}
                    </Typography>{" "}
                    {/* <Typography
                      fontSize={12}
                      mx={1}
                      color={"rgb(108, 117, 125)"}
                    >
                      ({item.count})
                    </Typography>{" "} */}
                  </Button>
                ))}
              </Box>
            )}
          </>
        }
      />
      <CardContent>
        <Grid container columnSpacing={1} rowSpacing={2} sx={{ px: 1 }}>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {!props.noSearch && (
                <TextField
                  value={dataState.filters.search}
                  sx={{
                    border: "1px solid #ffffff26",
                    borderRadius: "9999px",
                    backgroundColor: "#0f1535",
                    padding: "0px",
                    color: "#fff",
                    "& .MuiInputBase-formControl": {
                      width: "100%",
                      borderRadius: "9999px",
                    },
                    input: {
                      color: "#fff",
                      borderRadius: "9999px",
                    },
                  }}
                  onChange={(e) => {
                    setDataState((old) => ({
                      ...old,
                      filters: {
                        ...old.filters,
                        search: e.target.value,
                      },
                    }));
                  }}
                  size="small"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      props.fetchData();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{
                          color: "#fff",
                        }}
                        onClick={() => {
                          props.fetchData();
                        }}
                      >
                        <AiOutlineSearch />
                      </IconButton>
                    ),
                  }}
                  placeholder="Search"
                  fullWidth
                />
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <>
              {props.exel && <ExportButton data={props.data} fileName="data" />}
              {props.btn && (
                <Button
                  variant="contained"
                  startIcon={<IoIosAddCircleOutline size={25} />}
                  sx={{
                    background:
                      "linear-gradient(310deg, #7928ca, #ff0080) !important",
                    height: 40,
                  }}
                  onClick={() => handleAdd()}
                >
                  {props.btn}
                </Button>
              )}
              {props.delete && (
                <Button
                  variant="contained"
                  startIcon={<DeleteOutlineIcon size={25} />}
                  sx={{
                    background:
                      "linear-gradient(310deg, #7928ca, #ff0080) !important",
                    height: 40,
                  }}
                  onClick={() => handleAdd()}
                >
                  {props.btn}
                </Button>
              )}
            </>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
