import { useEffect } from "react";
import { Avatar, Box } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import Filters from "./Filters";
import {
  selectedStatusState,
  selectedTransactionTypeState,
} from "../../recoil/select";
import { GetTransactionsData } from "../../services/api/transactions";
import { FaArrowDown } from "react-icons/fa6";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);

  const [emailSearch, setEmailSearch] = useState(null);
  const [phoneSearch, setPhoneSearch] = useState(null);
  const [userNames, setUserNames] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [createdAfter, setCreatedAfter] = useState(null);
  const [createdBefore, setCreatedBefore] = useState(null);

  const selectedStatus = useRecoilValue(selectedStatusState);
  const state = useRecoilValue(openFilterState);
  const updateData = useRecoilValue(updateDataState);
  const transactionType = useRecoilValue(selectedTransactionTypeState);

  const resetConfirm = useResetRecoilState(confirmModalState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);
  useEffect(() => {
    setDataItem(null);
  }, [state]);

  const handelEdit = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("edit");
  };

  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const columns = [
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.transaction_type
                  ? `${capitalizeFirstLetter(params.row?.transaction_type)}`
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
            {params.row?.transaction_type === "deposit" && (
              <FaArrowDown color="#ff00ff" />
            )}
            {/* <ColumnText text={params.row?.job_title?.name_ar} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },
    {
      field: "user",
      headerName: "User",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={params.row?.user ? params.row?.user : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={params.row?.amount ? params.row?.amount : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            sx={{
              overflowX: "auto !important",
              scrollbarWidth: "none",
            }}
            alignItems={"center"}
            gap={2}
          >
            <ColumnText
              text={params.row?.description ? params.row?.description : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "related_order",
      headerName: "Related Order",
      flex: 1,
      headerClassName: "grid--header",
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.related_order
                  ? "#" + params.row?.related_order
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    GetTransactionsData(
      data.filters.skip,
      data.filters.take,
      data.filters.search,
      emailSearch,
      phoneSearch,
      transactionType,
      userNames,
      firstName,
      lastName,
      orderNumber,
      createdAfter,
      createdBefore
    )
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res.results,
          total: res?.count,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [
    filters,
    updateData,
    emailSearch,
    phoneSearch,
    transactionType,
    userNames,
    firstName,
    lastName,
    orderNumber,
    createdAfter,
    createdBefore,
  ]);

  return (
    <>
      <Box>
        <HeaderGridTabel
          title="Transactions"
          icon={
            <HomeWorkIcon
              size={25}
              className="color-primary"
              color={"#08001e"}
            />
          }
          btn={"Add Transactions"}
          fetchData={fetchData}
          isFilter={true}
        />
        <Box mt={2}>
          <DataGridTabel
            columns={columns}
            data={data}
            setData={setData}
            small={true}
          />
        </Box>
        {/* Modal */}
        <ModalInfo />
        <ModalDelete />
        {/* Fillter */}
        <Filters
          emailSearch={emailSearch}
          setEmailSearch={setEmailSearch}
          phoneSearch={phoneSearch}
          setPhoneSearch={setPhoneSearch}
          userNames={userNames}
          setUserNames={setUserNames}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          orderNumber={orderNumber}
          setOrderNumber={setOrderNumber}
          createdAfter={createdAfter}
          setCreatedAfter={setCreatedAfter}
          createdBefore={createdBefore}
          setCreatedBefore={setCreatedBefore}
          fetchData={fetchData}
        />
      </Box>
    </>
  );
};

export default Tabel;
