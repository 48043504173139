import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import {
  dataSelectCityState,
  dataSelectProvinceState,
  selectedJobFieldState,
} from "../../../recoil/select";
import GeneralModal from "../../GeneralModal/GeneralModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "leaflet/dist/leaflet.css";
import { posisionMapState } from "../../../recoil/location";
import { PostSlider, PatchSlider } from "../../../services/api/slider";
import axios from "axios";

export default function ModalInfo() {
  
  const [open, setOpen] = useRecoilState(openModalInfo);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [base64, setBase64] = useState(null);
  const [sendData, setSendData] = useState(false);
  const [loading, setLoading] = useState(false);

  const dataItem = useRecoilValue(dataClickItem);
  const methodRequest = useRecoilValue(methodRequestStatus);

  const setUpdateData = useSetRecoilState(updateDataState);

  // set file data
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    convertToBase64(file);
  };

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setBase64(reader.result);
    };
    reader.onerror = (error) => {
      console.error("Error converting file to base64:", error);
    };
  };

  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
setLoading(true)
    // function api request
    if (methodRequest === "add") {
      // data form
      const formData = new FormData();

      const infoData = {
        path: data.get("path"),
        image: base64,
      };

      // function api request
      PostSlider(infoData)
        .then((res) => {
          console.log(res);
          toast.success("Added successfully");
          handleClose();
          setUpdateData(Math.random());
          setLoading(false)
        })
        .catch((err) => {
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
          setLoading(false)
        });
    } else {
      const data = new FormData(event.currentTarget);
      // data form
      const formData = new FormData();

      const infoData = base64
        ? {
            image: base64,
            path: data.get("path"),
          }
        : {
            path: data.get("path"),
          };
      const idItem = dataItem?.id;
      PatchSlider(idItem, infoData)
        .then((res) => {
          console.log(res);
          toast.success("Edit successfully");
          handleClose();
          setUpdateData(Math.random());
          setSendData(true);
          setLoading(false)
        })
        .catch((err) => {
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
          setLoading(false)
        });
      formData.append("file", selectedFile);
    }
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open === false) {
      setSelectedFile(null);
    }
  }, [open]);

  useEffect(() => {
    console.log(base64);
  }, [base64]);

  // hide and show password
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <GeneralModal
      maxWidth="md"
      disabled={loading}
      open={open}
      onSubmit={handleSubmit}
      loading={loading}
      onClose={()=>{
        setLoading(false)
      }}
    >
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          File Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%" }} noValidate>
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Path"
                  name="path"
                  autoComplete="path"
                  autoFocus
                  defaultValue={dataItem?.path}
                />
                {selectedFile && (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="image"
                    style={{ width: "100%", height: "auto" }}
                  />
                )}
                {dataItem?.image && !selectedFile && (
                  <img
                    src={dataItem?.image}
                    alt="image"
                    style={{ width: "100%", height: "auto" }}
                  />
                )}
                <Button
                  margin="normal"
                  component="label"
                  variant="outlined"
                  sx={{ width: "100%", mt: 2 }}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <input
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
