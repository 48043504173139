import React, { useEffect, useState } from "react";
import Slider from "../components/Slidbar/Slidbar";
import { useRecoilState, useRecoilValue } from "recoil";
import { openModalCompleteInfo, userInfo } from "../recoil";
import { Box, Drawer } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { openSlidbarState, SidebarHightState } from "../recoil/global";
import RouterApp from "../Router";
import bgImage from "../assets/body-background-1.webp";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export const Layout = () => {
  const [open, setOpen] = useRecoilState(openSlidbarState);
  const [userInfoData, setUserInfoData] = useRecoilState(userInfo);
  const height = useRecoilValue(SidebarHightState);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          position: "relative",
          backgroundImage: `url(${bgImage})`,
          height: "100%",
          minHeight: "100vh",
          backgroundSize: "cover",
        }}
      >
        <Box component="main" sx={{ flexGrow: 1 }}>
          {/* <DrawerHeader sx={{ display: userInfoData ? "flex" : "none" }} /> */}

          <Box>
            <RouterApp />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
