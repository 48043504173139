import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { dataTabelState } from "../../recoil/Tabel";

export const SlidbarItem = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const setData = useSetRecoilState(dataTabelState);
  const [selected, setSelected] = useState(null);
  const itemRef = React.useRef(null);

  useEffect(() => {
    console.log(currentPath);
  }, [currentPath]);

  return (
    <ListItem
      disablePadding
      className="link"
      sx={{
        display: "block",
        width: "calc(100% - 1rem)",
        m: "0.5rem",
        borderRadius: "10px",
        backgroundColor:
          currentPath === props.path || selected ? "#ffffff0d" : "transparent",
        overflow: "hidden",
        boxShadow: "1px 4px 10px rgba(0,0,0,.1)",
        transition: "all 0.5s",
        ".textHover": {
          transition: "all 0.5s ",
        },
        "&:hover .textHover": {
          color: "#fff",
        },
      }}
      ref={itemRef}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: props.open ? "initial" : "center",
          px: 2.5,

          svg: {
            fill: currentPath === props.path || selected ? "#fff" : "#a7acb1",
          },
          "&:hover": {
            backgroundColor: "#ffffff0d",
            svg: {
              fill: "#fff",
            },
          },
        }}
        onClick={() => {
          setData((old) => ({
            ...old,
            filters: {
              ...old.filters,
              search: "",
            },
          }));
          props?.path
            ? navigate(props.path)
            : props.openDrop === props.index
            ? props.setOpenDrip(null)
            : props.setOpenDrip(props.index);
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: props.open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {props.icon}
        </ListItemIcon>
        {props?.open && (
          <ListItemText
            primary={props.text}
            className="textHover"
            sx={{ opacity: props.open ? 1 : 0, color: "#a7acb1" }}
          />
        )}
        {!props.path ? (
          props.openDrop === props.index ? (
            <ExpandLess sx={{ display: props.open ? "flex" : "none" }} />
          ) : (
            <ExpandMore sx={{ display: props.open ? "flex" : "none" }} />
          )
        ) : (
          ""
        )}
      </ListItemButton>
      {props.child && (
        <Collapse
          in={props.openDrop === props.index}
          timeout="auto"
          unmountOnExit
          sx={{ background: "#0f1534" }}
        >
          <List
            component="div"
            disablePadding
            sx={{
              background: "#0f1534",
              gap: 1,
            }}
          >
            {props.child.map((item, index) => {
              return (
                <ListItemButton
                  sx={{
                    pl: props.open ? 4 : 1.4,
                    background:
                      currentPath === item.path ? "#ffffff0d" : "#0f1534",
                    py: 1,
                    my: 1,
                    "&:hover": {
                      backgroundColor: "#ffffff0d",
                      svg: {
                        fill: "#fff",
                      },
                    },
                    ".textHover2": {
                      transition: "all 0.5s ",
                    },
                    "&:hover .textHover2": {
                      color: "#fff",
                    },
                  }}
                  key={index}
                  onClick={() => {
                    navigate(item.path);
                  }}
                  onFocus={(e) => {
                    itemRef.current.click();
                  }}
                >
                  <ListItemIcon
                    sx={{
                      svg: {
                        fill: currentPath === item.path ? "#fff" : "#a7acb1",
                      },
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.text}
                    className="textHover2"
                    sx={{
                      color: currentPath === item.path ? "#fff" : "#a7acb1",
                      opacity: props.open ? 1 : 0,
                      transition: "all 0.3s",
                    }}
                  />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      )}
    </ListItem>
  );
};
