import { deleteData, getData, postData, putData, patchData } from "..";

// get Product
export const GetProductData = async (
  skip,
  take,
  search,
  category,
  subCategory,
  tags,
  hasOpthions,
  isOpthions,
  disconted
) => {
  if ((skip !== undefined && take !== undefined) || search !== search) {
    try {
      // const request = await getData(`/product?page=${skip === 0 ? 1 : skip || take}&page_size=${take}&search=${search}&filter{is_discounted}=${is_discounted ? is_discounted : "" }&filter{has_options}=${has_options ? has_options : "" }&filter{is_option_product}=${is_option_product ? is_option_product : "" }&filter{available}=${available ? available : "" }&filter{tag}=${tag ? tag : "" }&filter{category_name}=${category_name ? category_name : "" }&filter{sub_category_name}=${sub_category_name ? sub_category_name : "" }`); // Handle the data
      const request = await getData(
        `/product?page=${skip === 0 ? 1 : skip || take}&page_size=${take}${
          search ? `&search=${search}` : ""
        }${tags ? `&tags=${tags}` : ""}${
          hasOpthions === true || hasOpthions === false
            ? `&has_options=${hasOpthions}`
            : ""
        }${
          isOpthions === true || isOpthions === false
            ? `&is_option_product=${isOpthions}`
            : ""
        }${
          disconted === true || disconted === false
            ? `&is_discounted=${disconted}`
            : ""
        }${category ? `&category__id=${category}` : ""}${
          subCategory ? `&sub_category__id=${subCategory}` : ""
        }`
      ); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// get Product By Id
export const GetProductByIdData = async (id) => {
  if (id) {
    try {
      const request = await getData(`/product/${id}`); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// post Product
export const PostProduct = async (data) => {
  try {
    const request = await postData(`/product/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// post Product , keys , images
export const PostProductKeysImages = async (
  data,
  images,
  keys,
  sectionList,
  wholesalePrices,
  setUpdateData
) => {
  try {
    const request = await postData(`/product/`, data); // Handle the data
    // post images
    if (images?.length > 0) {
      images.map(async (image) => {
        const data = {
          product: request.id,
          image_file: image.base64,
        };
        if (image?.state === "new") {
          await postData(`/product_image/`, data)
            .then((res) => {
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
    // post keys
    if (keys?.length > 0) {
      keys.map(async (key) => {
        const data = {
          product: request.id,
          key: key.key,
        };
        if (key?.state === "new") {
          await postData(`/product_key/`, data)
            .then((res) => {
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
    if (sectionList?.length > 0) {
      sectionList.map(async (section) => {
        const data = {
          product: request.id,
          seq: section.seq,
          title: section.title,
          title_ar: section.title_ar,
          content: section.content,
          content_ar: section.content_ar,
        };
        if (section?.state === "new") {
          await postData(`/product_section/`, data)
            .then((res) => {
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
    if (wholesalePrices?.length > 0) {
      wholesalePrices.map(async (whPrice) => {
        const data = {
          product: request.id,
          wholesale_user_type: whPrice.id,
          price: Number(whPrice.prices),
        };
        if (whPrice.new) {
          await patchData(
            `/product_wholesale_pricing/${
              request.wholesale_pricings.find(
                (item) => item?.wholesale_user_type === whPrice?.id
              )?.id
            }/`,
            data
          )
            .then((res) => {
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// patch Product , keys , images

export const PatchProductKeysImages = async (
  id,
  data,
  images,
  keys,
  sectionList,
  wholesalePrices,
  setUpdateData
) => {
  try {
    const request = await patchData(`/product/${id}/`, data); // Handle the data
    // post images
    if (images.length > 0) {
      images.map(async (image) => {
        if (image?.state === "new") {
          const data = {
            product: id,
            image_file: image.base64,
          };
          await postData(`/product_image/`, data)
            .then((res) => {
              console.log("res", res);
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (image?.state === "deleted") {
          await deleteData(`/product_image/${image.id}/`)
            .then((res) => {
              console.log("res", res);
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (image?.state === "updated") {
          const data = {
            product: id,
            image_file: image.base64,
          };
          await patchData(`/product_image/${image.id}/`, data)
            .then((res) => {
              console.log("res", res);
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
    if (keys.length > 0) {
      keys.map(async (key) => {
        if (key?.state === "new") {
          const data = {
            product: id,
            key: key.key,
          };
          await postData(`/product_key/`, data)
            .then((res) => {
              console.log("res", res);
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (key?.state === "deleted") {
          await deleteData(`/product_key/${key.id}/`)
            .then((res) => {
              console.log("res", res);
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (key?.state === "updated") {
          const data = {
            product: id,
            key: key.key,
          };
          await patchData(`/product_key/${key.id}/`, data)
            .then((res) => {
              console.log("res", res);
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
    if (sectionList.length > 0) {
      sectionList.map(async (section) => {
        if (section?.state === "new") {
          const data = {
            product: id,
            seq: section.seq,
            title: section.title,
            title_ar: section.title_ar,
            content: section.content,
            content_ar: section.content_ar,
          };
          await postData(`/product_section/`, data)
            .then((res) => {
              console.log("res", res);
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (section?.state === "deleted") {
          await deleteData(`/product_section/${section.id}/`)
            .then((res) => {
              console.log("res", res);
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (section?.state === "updated") {
          const data = {
            product: id,
            seq: section.seq,
            title: section.title,
            title_ar: section.title_ar,
            content: section.content,
            content_ar: section.content_ar,
          };
          await patchData(`/product_section/${section.id}/`, data)
            .then((res) => {
              console.log("res", res);
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
    if (wholesalePrices.length > 0) {
      wholesalePrices.map(async (whPrice) => {
        const data = {
          product: request.id,
          wholesale_user_type: whPrice.id,
          price: whPrice.prices,
        };
        if (whPrice.new) {
          await postData(`/product_wholesale_pricing/`, data)
            .then((res) => {
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          await patchData(`/product_wholesale_pricing/${whPrice.old_id}/`, data)
            .then((res) => {
              setUpdateData(Math.random());
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }

    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// patch Product
export const PatchProduct = async (id, data) => {
  try {
    const request = await patchData(`/product/${id}/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// put Product
export const PutProduct = async (id, data) => {
  try {
    const request = await putData(`/product/${id}/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// delete Product
export const DeleteProduct = async (id) => {
  try {
    const request = await deleteData(`/product/${id}`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
