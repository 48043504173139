import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/system";

const DropDown = (props) => {
  const divRef = useRef(null);

  const handleClickOutside = (event) => {
    // Check if the click was outside the div
    if (divRef.current && !divRef.current.contains(event.target)) {
      props.setOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    props?.open && (
      <Box
        key={props.key}
        ref={divRef}
        position={"absolute"}
        sx={{
          background: "#0f1535",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px #0000001a",
          border: "1px solid #ffffff5e",
          zIndex: 1000,
        }}
        minWidth={400}
        right={8}
        top={60}
      >
        <Box height={"100%"} width={"100%"} position={"relative"}>
          <Box
            width={15}
            height={15}
            position={"absolute"}
            top={-8}
            right={12}
            sx={{
              transform: "rotate(45deg)",
              background: "#0f1535",
              borderTop: "1px solid #ffffff5e",
              borderLeft: "1px solid #ffffff5e",
            }}
          ></Box>
          <Box>{props.children}</Box>
        </Box>
      </Box>
    )
  );
};

export default DropDown;
