import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  TextareaAutosize,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
  wholesaleTypesState,
} from "../../../recoil/global";
import {
  dataSelectCityState,
  dataSelectProvinceState,
  selectedJobFieldState,
  selectedTagsState,
  selectedKeyValidityState,
  selectedKeyUserCountState,
  selectedCategoryState,
  selectedSubCategoryState,
  selectedCompanyState,
} from "../../../recoil/select";
import GeneralModal from "../../GeneralModal/GeneralModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "leaflet/dist/leaflet.css";
import { posisionMapState } from "../../../recoil/location";
import {
  PostProduct,
  PostProductKeysImages,
  PatchProductKeysImages,
  PutProduct,
  PatchProduct,
} from "../../../services/api/product";
import SelectTags from "../../Selects/SelectTags";
import SelectKeyUserCount from "../../Selects/SelectKeyUserCount";
import SelectKeyValidity from "../../Selects/SelectKeyUserValidity";
import SelectCategory from "../../Selects/SelectCategory";
import SelectSubCategory from "../../Selects/SelectSubCategory";
import SelectCompany from "../../Selects/SelectCompany";
import DeleteIcon from "@mui/icons-material/Delete";
import { Girl } from "@mui/icons-material";
import MultiImage from "../../Others/MultiImage";
import ProductSection from "../../Others/ProductSection";
import MultiText from "../../Others/MultiText";
import axios from "axios";
import MultiWholesale from "../../Others/MultiWholesale";

export default function ModalInfo() {
  const [selectedTags, setSelectedTags] = useRecoilState(selectedTagsState);
  const [selectedKeyUserCount, setSelectedKeyUserCount] = useRecoilState(
    selectedKeyUserCountState
  );
  const [selectedKeyValidity, setSelectedKeyValidity] = useRecoilState(
    selectedKeyValidityState
  );
  const [selectedCategory, setSelectedCategory] = useRecoilState(
    selectedCategoryState
  );
  const [selectedSubCategory, setSelectedSubCategory] = useRecoilState(
    selectedSubCategoryState
  );
  const [selectedCompany, setSelectedCompany] =
    useRecoilState(selectedCompanyState);
  const [open, setOpen] = useRecoilState(openModalInfo);
  const [showPassword, setShowPassword] = useState(false);

  const [sendData, setSendData] = useState(false);
  const [imgList, setImgList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [keyList, setKeyList] = useState([]);
  const [originalSections, setOriginalSections] = useState([]);
  const [originalKeys, setOriginalKeys] = useState([]);
  const [originalImages, setOriginalImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wholesaleTypes, setWholesaleTypes] =
    useRecoilState(wholesaleTypesState);
  const [wholesalePrices, setWholesalePrices] = useState([]);

  const [productBase64, setProductBase64] = useState([]);

  const [dataItem, setDataItem] = useRecoilState(dataClickItem);
  const methodRequest = useRecoilValue(methodRequestStatus);

  const setUpdateData = useSetRecoilState(updateDataState);

  // const convertToBase64 = (file) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     setBase64(reader.result);
  //   };
  //   reader.onerror = (error) => {
  //     console.error("Error converting file to base64:", error);
  //   };
  // };

  // useEffect(() => {
  //   dataItem?.images?.length > 0 &&
  //     setProductBase64(
  //       dataItem?.images.map((item) => {
  //         return item.image_file;
  //       })
  //     );
  // }, [dataItem]);

  useEffect(() => {
    if (open) {
      dataItem ? setSelectedTags(dataItem?.tag) : setSelectedTags([]);
      dataItem?.keys_users_count?.count
        ? setSelectedKeyUserCount(dataItem?.keys_users_count?.count)
        : setSelectedKeyUserCount(0);
      dataItem?.keys_validity?.validity
        ? setSelectedKeyValidity(dataItem?.keys_validity.validity)
        : setSelectedKeyValidity(0);
      dataItem?.category
        ? setSelectedCategory(dataItem?.category)
        : setSelectedCategory(0);
      dataItem?.sub_category
        ? setSelectedSubCategory(dataItem?.sub_category)
        : setSelectedSubCategory(0);
      dataItem?.company
        ? setSelectedCompany(dataItem?.company)
        : setSelectedCompany(0);
      dataItem?.images ? setImgList(dataItem?.images) : setImgList([]);
      dataItem?.images ? setOriginalImages(dataItem?.images) : setImgList([]);
      dataItem?.sections
        ? setSectionList(dataItem?.sections)
        : setSectionList([]);
      dataItem?.sections
        ? setOriginalSections(dataItem?.sections)
        : setSectionList([]);
      dataItem?.product_keys
        ? dataItem.product_keys.length > 0 && setKeyList(dataItem?.product_keys)
        : setKeyList([]);
      dataItem?.product_keys
        ? dataItem?.product_keys?.length > 0 &&
          setOriginalKeys(dataItem?.product_keys)
        : setKeyList([]);

      let prices = [];
      dataItem?.wholesale_pricings
        ? dataItem?.wholesale_pricings?.length > 0 &&
          wholesaleTypes.map((item) => {
            prices.push({
              ...item,
              new:
                !dataItem.wholesale_pricings.filter(
                  (it) => it.wholesale_user_type === item.id
                )?.length > 0,
              old_id:
                dataItem.wholesale_pricings.find(
                  (it) => it.wholesale_user_type === item.id
                )?.id || null,
              prices:
                dataItem.wholesale_pricings.find(
                  (it) => it.wholesale_user_type === item.id
                )?.price || 0,
            });
            prices?.length === wholesaleTypes?.length &&
              setWholesalePrices(prices);
          })
        : wholesaleTypes.map((item) => {
            prices.push({
              ...item,
              new: true,
              prices: 0,
            });
            prices?.length === wholesaleTypes?.length &&
              setWholesalePrices(prices);
          });
    }
  }, [dataItem, open]);

  useEffect(() => {
    console.log("wholesalePrices", wholesalePrices);
  }, [wholesalePrices]);

  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    setLoading(true);
    // function api request
    if (!data.get("name") || !data.get("name_ar") || data.get("price")) {
      !data.get("name") && toast.error(`name: this filed is required`);
      !data.get("name_ar") && toast.error(`name_ar: this filed is required`);
      !data.get("price") && toast.error(`price: this filed is required`);
    }
    if (methodRequest === "add") {
      // data form
      const formData = new FormData();

      console.log("selectedKeyUserCount", selectedKeyUserCount);

      const infoData = {
        seq: data.get("seq"),
        name: data.get("name"),
        name_ar: data.get("name_ar"),
        discription: data.get("discription"),
        discription_ar: data.get("discription_ar"),
        cashback_amount: data.get("cashback"),
        qty: data.get("quantity"),
        SKU_code: data.get("SKU"),
        price: data.get("price"),
        old_price: data.get("old_price"),
        tutorial: data.get("tutorial"),
        download_link: data.get("download_link"),
        tag: selectedTags === "no Tag" ? "new" : selectedTags,
        keys_users_count:
          selectedKeyUserCount === 0 ? null : selectedKeyUserCount,
        keys_validity: selectedKeyValidity === 0 ? null : selectedKeyValidity,
        category: selectedCategory,
        sub_category: selectedSubCategory,
        company: selectedCompany,
      };

      !infoData.keys_users_count && delete infoData.keys_users_count;
      !infoData.keys_validity && delete infoData.keys_validity;
      !infoData.download_link && delete infoData.download_link;
      !infoData.tutorial && delete infoData.tutorial;
      !infoData.seq && delete infoData.seq;
      !infoData.discription && delete infoData.discription;
      !infoData.discription_ar && delete infoData.discription_ar;
      !infoData.cashback_amount && delete infoData.cashback_amount;
      !infoData.SKU_code && delete infoData.SKU_code;
      !infoData.old_price && delete infoData.old_price;
      !infoData.category && delete infoData.category;
      !infoData.sub_category && delete infoData.sub_category;
      !infoData.company && delete infoData.company;

      infoData.tag.length === 0 && delete infoData.tag;

      // function api request
      PostProductKeysImages(
        infoData,
        imgList,
        keyList,
        sectionList,
        wholesalePrices,
        setUpdateData
      )
        .then((res) => {
          console.log(res);
          toast.success("Added successfully");
          handleClose();
          setSelectedCategory(0);
          setSelectedSubCategory(0);
          setSelectedCompany(0);
          setSelectedKeyUserCount(0);
          setSelectedKeyValidity(0);
          setImgList([]);
          setSectionList([]);
          setKeyList([]);
          setSendData(true);
          setTimeout(() => {
            setUpdateData(Math.random());
          }, 1000);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
        });
    } else {
      const data = new FormData(event.currentTarget);
      // data form
      const formData = new FormData();
      console.log("function execute");
      const infoData = {
        seq: data.get("seq"),
        name: data.get("name"),
        name_ar: data.get("name_ar"),
        discription: data.get("discription"),
        discription_ar: data.get("discription_ar"),
        cashback_amount: data.get("cashback"),
        qty: data.get("quantity"),
        SKU_code: data.get("SKU"),
        price: data.get("price"),
        old_price: data.get("old_price"),
        tutorial: data.get("tutorial"),
        download_link: data.get("download_link"),
        tag: selectedTags === "no Tag" ? "new" : selectedTags,
        keys_users_count: selectedKeyUserCount ? selectedKeyUserCount : null,
        keys_validity: selectedKeyValidity ? selectedKeyValidity : null,
        category: selectedCategory,
        sub_category: selectedSubCategory,
        company: selectedCompany,
      };
      // remove all null data
      !infoData.keys_users_count && delete infoData.keys_users_count;
      !infoData.keys_validity && delete infoData.keys_validity;
      !infoData.download_link && delete infoData.download_link;
      !infoData.tutorial && delete infoData.tutorial;
      !infoData.seq && delete infoData.seq;
      !infoData.discription && delete infoData.discription;
      !infoData.discription_ar && delete infoData.discription_ar;
      !infoData.cashback_amount && delete infoData.cashback_amount;
      !infoData.SKU_code && delete infoData.SKU_code;
      !infoData.old_price && delete infoData.old_price;
      !infoData.category && delete infoData.category;
      !infoData.sub_category && delete infoData.sub_category;
      !infoData.company && delete infoData.company;

      const idItem = dataItem?.id;
      PatchProductKeysImages(
        idItem,
        infoData,
        imgList,
        keyList,
        sectionList,
        wholesalePrices,
        setUpdateData
      )
        .then((res) => {
          console.log(res);
          toast.success("Edit successfully");
          setSendData(true);
          handleClose();
          setSelectedCategory(0);
          setSelectedSubCategory(0);
          setSelectedCompany(0);
          setSelectedKeyUserCount(0);
          setSelectedKeyValidity(0);
          setImgList([]);
          setSectionList([]);
          setKeyList([]);
          setSendData(true);
          setTimeout(() => {
            setUpdateData(Math.random());
          }, 1000);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
        });
    }
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
    setDataItem(null);
    setImgList([]);
    setSectionList([]);
    setKeyList([]);
    setSelectedTags([]);
    setSelectedKeyUserCount(0);
    setSelectedKeyValidity(0);
    setSelectedCategory(0);
    setSelectedSubCategory(0);
  };

  // hide and show password
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <GeneralModal
      maxWidth="md"
      open={open}
      onSubmit={handleSubmit}
      loading={loading}
      onClose={() => {
        setImgList([]);
        setSectionList([]);
        setKeyList([]);
        setSelectedTags([]);
        setSelectedKeyUserCount(0);
        setSelectedKeyValidity(0);
        setSelectedCategory(0);
        setSelectedSubCategory(0);
        setLoading(false);
      }}
    >
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          Product Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate>
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  defaultValue={dataItem?.name}
                />
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="description"
                  label="Description"
                  name="description"
                  autoComplete="Description"
                  autoFocus
                  defaultValue={dataItem?.description}
                  multiline
                  rows={4}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate>
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="name_ar"
                  label="Name AR"
                  name="name_ar"
                  autoComplete="name_ar"
                  defaultValue={dataItem?.name_ar}
                />

                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="description_ar"
                  label="Description AR"
                  name="description_ar"
                  autoComplete="Description_ar"
                  defaultValue={dataItem?.description_ar}
                  multiline
                  rows={4}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate>
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="cashback"
                  label="Cashback"
                  name="cashback"
                  autoComplete="cashback"
                  defaultValue={dataItem?.cashback_amount}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  inputMode="numeric"
                />

                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="quantity"
                  label="Quantity"
                  name="quantity"
                  autoComplete="quantity"
                  defaultValue={dataItem?.qty}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate>
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="SKU"
                  label="SKU"
                  name="SKU"
                  autoComplete="SKU"
                  defaultValue={dataItem?.SKU_code}
                />

                <SelectTags />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate>
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="price"
                  label="Price"
                  name="price"
                  autoComplete="price"
                  defaultValue={dataItem?.price}
                  type="number"
                  inputMode="numeric"
                  onWheel={(e) => e.target.blur()}
                />
                <SelectKeyUserCount />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate>
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="old_price"
                  label="Old Price"
                  name="old_price"
                  autoComplete="old_price"
                  defaultValue={dataItem?.old_price}
                  inputMode="numeric"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                />
                <SelectKeyValidity />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
                noValidate
              >
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="tutorial"
                  label="Tutorial"
                  name="tutorial"
                  autoComplete="Tutorial"
                  defaultValue={dataItem?.tutorial}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
                noValidate
              >
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  id="download_link"
                  label="Download Link"
                  name="download_link"
                  autoComplete="download_link"
                  defaultValue={dataItem?.download_link}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <MultiWholesale
            setWholesalePrices={setWholesalePrices}
            wholesalePrices={wholesalePrices}
          />
        </Grid>

        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          Product Category & Company
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate>
                <SelectCategory />
                <SelectCompany />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
                noValidate
              >
                <SelectSubCategory />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          Product Images
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <MultiImage
              imgList={imgList}
              setImgList={setImgList}
              imageKey={"image_file"}
            />
          </Grid>
        </Grid>
        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          Product Sections
        </Typography>
        <ProductSection
          sectionList={sectionList}
          setSectionList={setSectionList}
        />
        <Typography
          sx={{ mx: 2, mt: 2 }}
          variant="h5"
          fontSize={18}
          fontWeight={500}
        >
          Product Keys
        </Typography>
        <MultiText keyList={keyList} setKeyList={setKeyList} />
      </Box>
    </GeneralModal>
  );
}
