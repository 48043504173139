// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.color-primary {
  color: #08001e;
}

.MuiPaper-elevation8 {
  background: #0f1535 !important;
  color: #fff !important;
}

.card-data-grid .MuiCardContent-root,
.css-sst63a-MuiDialogContent-root {
  padding: 0 !important;
}

.MuiDialog-paper {
  width: 100%;
}

.location-map {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  min-width: 400px;
  border: 1px solid #5b5b5b !important;
}

.location-map .btn-location-live {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 9999;
  background-color: rgba(256, 256, 256, 0.75);
  border: 1px solid #08001e;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
}

.location-map .btn-location-live svg:hover {
  scale: 1.1;
}

.leaflet-control-attribution {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;;EAEE,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,aAAa;EACb,2CAA2C;EAC3C,yBAAyB;EACzB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n.color-primary {\n  color: #08001e;\n}\n\n.MuiPaper-elevation8 {\n  background: #0f1535 !important;\n  color: #fff !important;\n}\n\n.card-data-grid .MuiCardContent-root,\n.css-sst63a-MuiDialogContent-root {\n  padding: 0 !important;\n}\n\n.MuiDialog-paper {\n  width: 100%;\n}\n\n.location-map {\n  position: relative;\n  border-radius: 5px;\n  overflow: hidden;\n  min-width: 400px;\n  border: 1px solid #5b5b5b !important;\n}\n\n.location-map .btn-location-live {\n  position: absolute;\n  bottom: 10px;\n  left: 10px;\n  z-index: 9999;\n  background-color: rgba(256, 256, 256, 0.75);\n  border: 1px solid #08001e;\n  border-radius: 5px;\n  width: 40px;\n  height: 40px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: 0.3s all ease-in-out;\n}\n\n.location-map .btn-location-live svg:hover {\n  scale: 1.1;\n}\n\n.leaflet-control-attribution {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
