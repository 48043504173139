import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import GeneralModal from "../../GeneralModal/GeneralModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "leaflet/dist/leaflet.css";
import {
  PostSubCategory,
  PostSubCategoryWithSlider,
  PutSubCategory,
  PatchSubCategoryWithSlider,
} from "../../../services/api/subCategory";
import MultiImage from "../../Others/MultiImage";

import SelectCategory from "../../Selects/SelectCategory";
import { selectedCategoryState } from "../../../recoil/select";

export default function ModalInfo() {
  const [open, setOpen] = useRecoilState(openModalInfo);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [sendData, setSendData] = useState(false);
  const [base64, setBase64] = useState(null);

  const [selectedCategory, setSelectedCategory] = useRecoilState(
    selectedCategoryState
  );

  const [imgList, setImgList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataItem, setDataItem] = useRecoilState(dataClickItem);
  const methodRequest = useRecoilValue(methodRequestStatus);

  const setUpdateData = useSetRecoilState(updateDataState);

  useEffect(() => {
    if (open) {
      dataItem
        ? setSelectedFileUrl(dataItem?.image_file)
        : setSelectedFileUrl(null);
      dataItem
        ? setImgList(dataItem?.sub_category_slider_items)
        : setImgList([]);
      dataItem
        ? setSelectedCategory(dataItem?.category)
        : setSelectedCategory(null);
    }
  }, [dataItem, open]);

  // set file data
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedFileUrl(URL.createObjectURL(file));
    console.log(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setBase64(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    console.log("imgList", imgList);
  }, [imgList]);

  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setLoading(true)
    // function api request
    if (methodRequest === "add") {
      // data form
      const formData = new FormData();

      const infoData = {
        seq: data.get("seq"),
        name: data.get("name"),
        name_ar: data.get("name_ar"),
        image_file: base64,
        category: selectedCategory,
        sub_category_slider_items: imgList
          .filter((item) => item.state === "new")
          .map((item) => {
            if (item?.state === "new") {
              return {
                image: item?.base64,
                path: item?.path,
              };
            }
          }),
      };

      console.log(infoData);
      let error = false;
      imgList.map((item, index) => {
        if (!item.path) {
          toast.error(`Please add image path in image ${index + 1}`);
          error = true;
        }
      });

      // function api request
      !error &&
        PostSubCategoryWithSlider(infoData, imgList, setUpdateData)
          .then((res) => {
            console.log(res);
            toast.success("Added successfully");
            handleClose();
            setSelectedCategory(null);
            setTimeout(() => {
              setUpdateData(Math.random());
            }, 1000);
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
            if (err.response) {
              const errorData = err.response.data;

              if (typeof errorData === "object" && !Array.isArray(errorData)) {
                // If error data is an object, iterate over its keys
                Object.keys(errorData).forEach((key) => {
                  const messages = errorData[key];
                  if (Array.isArray(messages)) {
                    messages.forEach((message) => {
                      toast.error(key + ": " + message);
                    });
                  } else {
                    toast.error(key + ": " + messages);
                  }
                });
              } else if (typeof errorData.message === "string") {
                // If there's a 'message' field, display it directly
                toast.error(errorData.message);
              } else {
                // Handle other types of error data as needed
                toast.error("Unexpected error occurred");
              }
            } else {
              // If there's no response from the server
              toast.error("Check your internet connection");
            }
          });
    } else {
      const data = new FormData(event.currentTarget);
      // data form
      const formData = new FormData();

      const infoData = base64
        ? {
            seq: data.get("seq"),
            name: data.get("name"),
            name_ar: data.get("name_ar"),
            image_file: base64,
            category: selectedCategory,
          }
        : {
            seq: data.get("seq"),
            name: data.get("name"),
            name_ar: data.get("name_ar"),
            category: selectedCategory,
          };
      const idItem = dataItem?.id;
      PatchSubCategoryWithSlider(idItem, infoData, imgList, setUpdateData)
        .then((res) => {
          console.log(res);
          toast.success("Edit successfully");
          handleClose();
          setUpdateData(Math.random());
          setSendData(true);
          setSelectedCategory(null);
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
        });
    }
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
    setSelectedCategory(null);
    setDataItem(null);
  };

  return (
    <GeneralModal
      maxWidth="md"
      open={open}
      onSubmit={handleSubmit}
      loading={loading}
      onClose={() => {
        setSelectedCategory(null);
        setDataItem(null);
        setLoading(false)
      }}
    >
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          Sub Category Information
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box noValidate>
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  defaultValue={dataItem?.name}
                />
                <TextField
                  size="small"
                  margin="normal"
                  fullWidth
                  required
                  id="seq"
                  label="SEQ"
                  name="seq"
                  autoComplete="seq"
                  autoFocus
                  defaultValue={dataItem?.seq}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
                noValidate
              >
                <TextField
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  name="name_ar"
                  label="Name AR"
                  type="text"
                  id="name_ar"
                  defaultValue={dataItem?.name_ar}
                />
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <SelectCategory required />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                mx: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                noValidate
              >
                <Box
                  sx={{
                    width: "50%",
                  }}
                >
                  {selectedFileUrl && (
                    <img
                      src={selectedFileUrl}
                      alt="Image"
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                  <Button
                    margin="normal"
                    component="label"
                    variant="outlined"
                    sx={{ width: "100%", mt: 2 }}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file
                    <input
                      type="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Typography sx={{ mx: 2 }}>Sub Category Slider</Typography>
        <MultiImage
          imgList={imgList}
          setImgList={setImgList}
          imageKey={"image"}
          path={"path"}
        />
      </Box>
    </GeneralModal>
  );
}
