import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Avatar, InputBase, Paper } from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userInfo } from "../../recoil";
import { openDropState, openSlidbarState } from "../../recoil/global";
import { MenuProfile } from "../Auth/MenuProfile";

import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import { GetNotificationsData } from "../../services/api/notifications";
import { notificationsState } from "../../recoil";
import { updateDataState, wholesaleTypesState } from "../../recoil/global";
import { dataTabelState } from "../../recoil/Tabel";
import {
  selectedCategoryState,
  selectedCityState,
  selectedCompanyState,
  selectedCreatedFromDateState,
  selectedCreatedToDateState,
  selectedStatusState,
  selectedSubCategoryState,
  selectedTagsState,
  selectedUserState,
  selectedCurencyState,
  selectedExpiredState,
  selectedIsViewState,
  selectedKeyUserCountState,
  selectedKeyValidityState,
  selectedKeyValidityUnitState,
  selectedNotificationLevelState,
  selectedPaymentMethodState,
  selectedPaymentStatus,
  selectedTransactionTypeState,
  selectedWholeSaleState,
  selectedTableItemState,
} from "../../recoil/select";
import CircularProgress from "@mui/material/CircularProgress";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import { CalendarMonth } from "@mui/icons-material";
import DropDown from "../Others/DropDown";

import tempNotImage from "../../assets/003-office.png";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const SlidbarTop = () => {
  const [notificationsData, setNotificationsData] =
    useRecoilState(notificationsState);

  const [open, setOpen] = useRecoilState(openSlidbarState);
  const [openDrop, setOpenDrip] = useRecoilState(openDropState);
  const [wholesaleTypes, setWholesaleTypes] =
    useRecoilState(wholesaleTypesState);
  const setUpdateData = useSetRecoilState(updateDataState);
  const setCategory = useSetRecoilState(selectedCategoryState);
  const setSubCategory = useSetRecoilState(selectedSubCategoryState);
  const setCompany = useSetRecoilState(selectedCompanyState);
  const setCity = useSetRecoilState(selectedCityState);
  const setUser = useSetRecoilState(selectedUserState);
  const setTags = useSetRecoilState(selectedTagsState);
  const setCreatedFromDate = useSetRecoilState(selectedCreatedFromDateState);
  const setCreatedToDate = useSetRecoilState(selectedCreatedToDateState);
  const setPaymentMethod = useSetRecoilState(selectedPaymentMethodState);
  const setPaymentStatus = useSetRecoilState(selectedPaymentStatus);
  const setTransactionType = useSetRecoilState(selectedTransactionTypeState);
  const setWholeSale = useSetRecoilState(selectedWholeSaleState);
  const setKeyUserCount = useSetRecoilState(selectedKeyUserCountState);
  const setKeyValidity = useSetRecoilState(selectedKeyValidityState);
  const setKeyValidityUnit = useSetRecoilState(selectedKeyValidityUnitState);
  const setExpired = useSetRecoilState(selectedExpiredState);
  const setIsView = useSetRecoilState(selectedIsViewState);
  const setNotificationLevel = useSetRecoilState(
    selectedNotificationLevelState
  );
  const setCurrency = useSetRecoilState(selectedCurencyState);
  const setSelectedStatus = useSetRecoilState(selectedStatusState);
  const setData = useSetRecoilState(dataTabelState);
  const setSelectedTableItems = useSetRecoilState(selectedTableItemState);

  const [isLoading, setIsLoading] = useState(3);
  const [windowScroll, setWindowScroll] = useState(0);
  const [dropDown, setDropDown] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const inputRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setWindowScroll(window.scrollY);
      console.log(window.scrollY);
    });

    return () => {
      window.removeEventListener("scroll", () => {
        setWindowScroll(window.scrollY);
      });
    };
  }, []);

  useEffect(() => {
    setCategory("");
    setSubCategory("");
    setCompany("");
    setCity("");
    setUser("");
    setTags("");
    setCreatedFromDate("");
    setCreatedToDate("");
    setPaymentMethod("");
    setPaymentStatus("");
    setTransactionType("");
    setWholeSale("");
    setKeyUserCount("");
    setKeyValidity("");
    setKeyValidityUnit("");
    setExpired("");
    setIsView("");
    setNotificationLevel("");
    setCurrency("");
    setSelectedStatus("");
    setSelectedTableItems([]);
    setData({
      data: [],
      loading: false,
      dialog: false,
      filters: {
        skip: 0,
        take: 25,
        page: 0,
        search: "",
      },
    });
  }, []);

  useEffect(() => {
    setWholesaleTypes([]);
    axios
      .get("/auth/wholesale_user_type/")
      .then((res) => {
        setWholesaleTypes(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [userInfoData, setUserInfoData] = useRecoilState(userInfo);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    GetNotificationsData(0, 25)
      .then((res) => {
        console.log(res);
        setNotificationsData(res.results);
        setIsLoading((old) => old + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleOpenDatePicker() {
    const datePicker = document.getElementById("date-picker-shiping");
    if ("showPicker" in HTMLInputElement.prototype) {
      datePicker.showPicker();
    } else {
      datePicker.click();
      datePicker.focus();
    }
  }

  return (
    <Box
      sx={{
        top: 0,
        width: "auto",
        position: "fixed",
        zIndex: 1,
        backdropFilter: windowScroll > 30 ? "blur(2.625rem)" : "blur(0rem)",
        borderBottom:
          windowScroll > 30 ? "1px solid #ffffff26" : "0px solid #ffffff26",
        transition: "all 0.5s",
        transition: theme.transitions.create(["backdropFilter"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        transition: theme.transitions.create(["borderBottom"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.down("xs")]: {
          width: open ? "66.66%" : "95.83%", // xs = 4/12 or 1/12
        },
        [theme.breakpoints.down("md")]: {
          width: open ? "79.16%" : "95.83%", // md = 3/12 or 1/12
        },
        [theme.breakpoints.up("xl")]: {
          width: open ? "83.33%" : "95.83%", // xl = 2/12 or 0.5/12
        },
      }}
    >
      <CssBaseline />

      <Box
        position="static"
        sx={{
          background: "transparent",
          display: userInfoData ? "flex" : "none",
          zIndex: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 1,
              borderRadius: "50%",
              background: "transparent",
              "&:hover": {
                backgroundColor: "#181f4a",
              },

              svg: {
                fill: "#fff",
              },
            }}
            hover
          >
            {open ? (
              theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )
            ) : (
              <MenuIcon />
            )}
          </IconButton>
          <Box
            sx={{
              width: "100%",
              px: 3,
            }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                border: "1px solid #ffffff26",
                background: "#0f1535",
              }}
            >
              <InputBase
                sx={{
                  ml: 1,
                  flex: 1,
                  background: "#0f1535",
                  color: "#fff",
                  svg: { fill: "#fff" },
                  appearance: "none",
                  "input[type='date']::-webkit-calendar-picker-indicator": {
                    background: "transparent",
                    bottom: 0,
                    color: "transparent",
                    cursor: "pointer",
                    height: "auto",
                    left: 0,
                    position: "absolute",
                    right: 0,
                    top: 0,
                    width: "auto",
                  },
                }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                type="Date"
                ref={inputRef}
                id="date-picker-shiping"
              />
              <IconButton
                type="button"
                aria-label="search"
                color="inherit"
                sx={{
                  svg: { fill: "#fff" },
                }}
                onClick={() => {
                  handleOpenDatePicker();
                }}
              >
                <CalendarMonth />
              </IconButton>
            </Paper>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box position={"relative"}>
              <IconButton
                color="inherit"
                edge="start"
                sx={{
                  marginRight: 1,
                  borderRadius: "999px",
                  width: "50px",
                  height: "50px",
                  background: "transparent",
                  "&:hover": {
                    backgroundColor: "#181f4a",
                  },

                  svg: {
                    fill: "#bbff00",
                  },
                }}
                hover
                onClick={() => {
                  setDropDown(
                    dropDown === "notifications" ? false : "notifications"
                  );
                  console.log("click");
                }}
              >
                <Box position={"relative"}>
                  <NotificationsNoneOutlinedIcon />
                  <Box
                    sx={{
                      position: "absolute",
                      top: -12,
                      right: -12,
                      background: "#f01414",
                      borderRadius: "999px",
                      width: "25px",
                      height: "25px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        background: "transparent",
                        borderRadius: "50%",

                        fontSize: "12px",
                      }}
                    >
                      {notificationsData.length}
                    </Typography>
                  </Box>
                </Box>
              </IconButton>
              <DropDown
                open={dropDown === "notifications"}
                setOpen={setDropDown}
                key={"notifications"}
              >
                <Typography
                  noWrap
                  width={405}
                  color={"#fff"}
                  py={1}
                  px={2}
                  sx={{
                    fontSize: "1rem",
                    borderBottom: "1px solid #ffffff26",
                  }}
                >
                  Product Notification
                </Typography>
                <Box
                  sx={{
                    overflowY: "hidden",
                    overflowX: "hidden",
                    "& .hide": {
                      display: "block",
                    },
                    "&:hover": {
                      overflowY: "auto",
                      "& .hide": {
                        display: "none",
                      },
                    },
                    "&::-webkit-scrollbar": {
                      width: 5,
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#ffffff5e",
                      borderColor: "#ffffff5e",
                      borderRadius: 5,
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                  }}
                  maxHeight={400}
                >
                  {notificationsData.length > 0 &&
                    notificationsData.map((item, index) => (
                      <Box
                        key={item.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          py: 2,
                          px: 2,
                          borderBottom: "1px solid #ffffff26",
                          svg: {
                            fill: "#fff !important",
                            stroke: "#fff",
                            display: "none",
                          },

                          "&:hover": {
                            background: "#181f4a",
                            svg: {
                              fill: "#fff !important",
                              stroke: "#fff",
                              display: "block",
                            },
                          },
                        }}
                        width={400}
                        hover
                      >
                        <Avatar
                          sx={{
                            width: 50,
                            height: 50,
                            background: "#0f1535",
                          }}
                          src={item.image ? item.image : tempNotImage}
                        />
                        <Box
                          mx={1}
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                        >
                          <Typography
                            noWrap
                            color={"#fff"}
                            sx={{
                              fontSize: "0.875rem",
                            }}
                          >
                            {item.name || "Microsoft Office 2019"}
                          </Typography>
                          <Typography
                            noWrap
                            color={"#ced2d8"}
                            sx={{
                              fontSize: "0.875rem",
                            }}
                            width={250}
                            textOverflow={"ellipsis"}
                          >
                            {item.des ||
                              " Your Product have only 10 piece in the store you should refill "}
                          </Typography>
                          <Typography
                            noWrap
                            color={"#818893"}
                            sx={{
                              fontSize: "0.875rem",
                            }}
                            width={250}
                            textOverflow={"ellipsis"}
                          >
                            {moment(item.created).fromNow()}
                          </Typography>
                        </Box>
                        <Box ml={"auto"}>
                          <CloseIcon />
                        </Box>
                        <Box className="hide" width={5}></Box>
                      </Box>
                    ))}
                </Box>
              </DropDown>
            </Box>
            <Box position={"relative"}>
              <IconButton
                color="inherit"
                edge="start"
                sx={{
                  marginRight: 1,
                  borderRadius: "999px",
                  width: "50px",
                  height: "50px",
                  background: "transparent",
                  "&:hover": {
                    backgroundColor: "#181f4a",
                  },

                  svg: {
                    fill: "#7a8eff",
                  },
                }}
                hover
                onClick={() =>
                  setDropDown(dropDown === "users" ? false : "users")
                }
              >
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  <PersonAddOutlinedIcon />
                  <Box
                    sx={{
                      position: "absolute",
                      top: -12,
                      right: -12,
                      background: "#f01414",
                      borderRadius: "999px",
                      width: "25px",
                      height: "25px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        background: "transparent",
                        borderRadius: "50%",

                        fontSize: "12px",
                      }}
                    >
                      {0}
                    </Typography>
                  </Box>
                </Box>
              </IconButton>
              <DropDown
                open={dropDown === "users"}
                setOpen={setDropDown}
                key={"users"}
              >
                <Typography
                  noWrap
                  width={405}
                  color={"#fff"}
                  py={1}
                  px={2}
                  sx={{
                    fontSize: "1rem",
                    borderBottom: "1px solid #ffffff26",
                  }}
                >
                  New Users
                </Typography>
                <Box
                  sx={{
                    overflowY: "hidden",
                    overflowX: "hidden",
                    "& .hide": {
                      display: "block",
                    },
                    "&:hover": {
                      overflowY: "auto",
                      "& .hide": {
                        display: "none",
                      },
                    },
                    "&::-webkit-scrollbar": {
                      width: 5,
                      backgroundColor: "transparent",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#ffffff5e",
                      borderColor: "#ffffff5e",
                      borderRadius: 5,
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                  }}
                  maxHeight={400}
                >
                  {notificationsData.length > 0 &&
                    notificationsData.map((item, index) => (
                      <Box
                        key={item.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          py: 2,
                          px: 2,
                          borderBottom: "1px solid #ffffff26",
                          svg: {
                            fill: "#fff !important",
                            stroke: "#fff",
                            display: "none",
                          },

                          "&:hover": {
                            background: "#181f4a",
                            svg: {
                              fill: "#fff !important",
                              stroke: "#fff",
                              display: "block",
                            },
                          },
                        }}
                        width={400}
                        hover
                      >
                        <Avatar
                          sx={{
                            width: 50,
                            height: 50,
                            background: "#0f1535",
                          }}
                          src={item.image ? item.image : tempNotImage}
                        />
                        <Box
                          mx={1}
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                        >
                          <Typography
                            noWrap
                            color={"#fff"}
                            sx={{
                              fontSize: "0.875rem",
                            }}
                          >
                            {item.name || "Microsoft Office 2019"}
                          </Typography>
                          <Typography
                            noWrap
                            color={"#ced2d8"}
                            sx={{
                              fontSize: "0.875rem",
                            }}
                            width={290}
                            textOverflow={"ellipsis"}
                          >
                            {item.des ||
                              " Your Product have only 10 piece in the store you should refill "}
                          </Typography>
                          <Typography
                            noWrap
                            color={"#818893"}
                            sx={{
                              fontSize: "0.875rem",
                            }}
                            width={250}
                            textOverflow={"ellipsis"}
                          >
                            {moment(item.created).fromNow()}
                          </Typography>
                        </Box>
                        <Box mr={"auto"}>
                          <CloseIcon />
                        </Box>
                        <Box className="hide" width={5}></Box>
                      </Box>
                    ))}
                </Box>
              </DropDown>
            </Box>

            <Box>
              <MenuProfile />
            </Box>
          </Box>
        </Toolbar>
      </Box>
    </Box>
  );
};

export default SlidbarTop;
