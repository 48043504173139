import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import img_logo from "../../assets/images/logo.png";
import { InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { userInfo } from "../../recoil";
import {
  openDropState,
  openSlidbarState,
  SidebarHightState,
} from "../../recoil/global";
import WorkIcon from "@mui/icons-material/Work";
import TitleIcon from "@mui/icons-material/Title";
import { SlidbarItem } from "./SlidbarItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BadgeIcon from "@mui/icons-material/Badge";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import LogoutIcon from "@mui/icons-material/Logout";
import { MenuProfile } from "../Auth/MenuProfile";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import MapIcon from "@mui/icons-material/Map";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CategoryIcon from "@mui/icons-material/Category";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import BusinessIcon from "@mui/icons-material/Business";
import InventoryIcon from "@mui/icons-material/Inventory";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import SliderIcon from "@mui/icons-material/Slideshow";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import KeyIcon from "@mui/icons-material/Key";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import UersIcon from "@mui/icons-material/Group";
import TuneIcon from "@mui/icons-material/Tune";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { updateDataState, wholesaleTypesState } from "../../recoil/global";
import { dataTabelState } from "../../recoil/Tabel";
import {
  selectedCategoryState,
  selectedCityState,
  selectedCompanyState,
  selectedCreatedFromDateState,
  selectedCreatedToDateState,
  selectedStatusState,
  selectedSubCategoryState,
  selectedTagsState,
  selectedUserState,
  selectedCurencyState,
  selectedExpiredState,
  selectedIsViewState,
  selectedKeyUserCountState,
  selectedKeyValidityState,
  selectedKeyValidityUnitState,
  selectedNotificationLevelState,
  selectedPaymentMethodState,
  selectedPaymentStatus,
  selectedTransactionTypeState,
  selectedWholeSaleState,
} from "../../recoil/select";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";
import { icon } from "leaflet";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { width } from "@mui/system";

const Slider = () => {


  const theme = useTheme();
  const [open, setOpen] = useRecoilState(openSlidbarState);
  const [openDrop, setOpenDrip] = useRecoilState(openDropState);
  const [wholesaleTypes, setWholesaleTypes] =
    useRecoilState(wholesaleTypesState);
  const setUpdateData = useSetRecoilState(updateDataState);
  const setCategory = useSetRecoilState(selectedCategoryState);
  const setSubCategory = useSetRecoilState(selectedSubCategoryState);
  const setCompany = useSetRecoilState(selectedCompanyState);
  const setCity = useSetRecoilState(selectedCityState);
  const setUser = useSetRecoilState(selectedUserState);
  const setTags = useSetRecoilState(selectedTagsState);
  const setCreatedFromDate = useSetRecoilState(selectedCreatedFromDateState);
  const setCreatedToDate = useSetRecoilState(selectedCreatedToDateState);
  const setPaymentMethod = useSetRecoilState(selectedPaymentMethodState);
  const setPaymentStatus = useSetRecoilState(selectedPaymentStatus);
  const setTransactionType = useSetRecoilState(selectedTransactionTypeState);
  const setWholeSale = useSetRecoilState(selectedWholeSaleState);
  const setKeyUserCount = useSetRecoilState(selectedKeyUserCountState);
  const setKeyValidity = useSetRecoilState(selectedKeyValidityState);
  const setKeyValidityUnit = useSetRecoilState(selectedKeyValidityUnitState);
  const setExpired = useSetRecoilState(selectedExpiredState);
  const setIsView = useSetRecoilState(selectedIsViewState);
  const setNotificationLevel = useSetRecoilState(
    selectedNotificationLevelState
  );
  const setCurrency = useSetRecoilState(selectedCurencyState);
  const setSelectedStatus = useSetRecoilState(selectedStatusState);
  const setData = useSetRecoilState(dataTabelState);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(3);
  const [hover, setHover] = useState(false);

  const [height, setHeight] = useRecoilState(SidebarHightState);

  const slidbarRef = useRef(null);

  useEffect(() => {
    setCategory("");
    setSubCategory("");
    setCompany("");
    setCity("");
    setUser("");
    setTags("");
    setCreatedFromDate("");
    setCreatedToDate("");
    setPaymentMethod("");
    setPaymentStatus("");
    setTransactionType("");
    setWholeSale("");
    setKeyUserCount("");
    setKeyValidity("");
    setKeyValidityUnit("");
    setExpired("");
    setIsView("");
    setNotificationLevel("");
    setCurrency("");
    setSelectedStatus("");
    setData({
      data: [],
      loading: false,
      dialog: false,
      filters: {
        skip: 0,
        take: 25,
        page: 0,
        search: "",
      },
    });
  }, []);

  useEffect(() => {
    setWholesaleTypes([]);
    axios
      .get("/auth/wholesale_user_type/")
      .then((res) => {
        setWholesaleTypes(res.data.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [userInfoData, setUserInfoData] = useRecoilState(userInfo);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    console.log("isLoading", isLoading);
  }, [isLoading]);

  useEffect(() => {
    setTimeout(() => {
      setHeight(slidbarRef?.current?.offsetHeight);
    }, 501);
  }, [hover, open]);

  const dataSlidbar = [
    {
      icon: <HomeIcon className="menu-icon" />,
      text: "Home",
      path: "/",
    },
    {
      icon: <NotificationsActiveIcon className="menu-icon" />,
      text: "Notifications",
      child: [
        {
          icon: <NotificationsActiveIcon className="menu-icon" />,
          text: "Order Notifications",
        },
        {
          icon: <NotificationsActiveIcon className="menu-icon" />,
          text: "Product Notifications",
        },
      ],
    },
    {
      icon: <ShoppingCartIcon className="menu-icon" />,
      text: "Order",
      path: "/order",
    },

    {
      icon: <InventoryIcon className="menu-icon" />,
      text: "Product",
      child: [
        {
          icon: <InventoryIcon className="menu-icon" />,
          text: "All Product",
          path: "/product",
        },
        {
          icon: <SubdirectoryArrowRightIcon className="menu-icon" />,
          text: "Category",
          path: "/category",
        },
        {
          icon: <SubdirectoryArrowRightIcon className="menu-icon" />,
          text: "Sub Category",
          path: "/sub_category",
        },
        {
          icon: <BusinessIcon className="menu-icon" />,
          text: "Company",
          path: "/company",
        },
      ],
    },
    {
      icon: <TuneIcon className="menu-icon" />,
      text: "Config",
      child: [
        {
          icon: <KeyIcon className="menu-icon" />,
          text: "Key Users Count",
          path: "/keyUsersCount",
        },
        {
          icon: <AccessTimeIcon className="menu-icon" />,
          text: "Key Validity",
          path: "/keyValidity",
        },
        {
          icon: <SupportAgentIcon className="menu-icon" />,
          text: "Support",
          path: "/support",
        },
        {
          icon: <FileCopyIcon className="menu-icon" />,
          text: "Files",
          path: "/files",
        },
        {
          icon: <SliderIcon className="menu-icon" />,
          text: "Home Slider",
          path: "/homeSlider",
        },
      ],
    },
    {
      icon: <AttachMoneyIcon className="menu-icon" />,
      text: "Transactions",
      path: "/transactions",
    },
    {
      icon: <UersIcon className="menu-icon" />,
      text: "Users",
      path: "/users",
    },
    {
      icon: <HomeWorkIcon className="menu-icon" />,
      text: "WholeSale",
      path: "/wholesale",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        position: "fixed",
        bgcolor: "#0f1535",
        zIndex: 2,
        height: "100vh",
        overflowY: "auto",
        borderRight: "1px solid #ffffff26",
        transition: theme.transitions.create(["width"], {
          easing: theme.transitions.easing.sharp,
          duration: 500,
        }),
        [theme.breakpoints.down("xs")]: {
          width: open || hover ? "33.33%" : 80, // xs = 4/12 or 1/12
        },
        [theme.breakpoints.down("md")]: {
          width: open || hover ? "25%" : 80, // md = 3/12 or 1/12
        },
        [theme.breakpoints.up("xl")]: {
          width: open || hover? "16.67%" : 80, // xl = 2/12 or 0.5/12
        },
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}

    >
      <CssBaseline />

      <Box
        variant="permanent"
        open={open}
        sx={{ display: userInfoData ? "flex" : "none", width: "100%" }}
      >
        <Divider />
        <List sx={{ height: "100%", width: "100%" }}>
          {
            <ListItem
              disablePadding
              sx={{
                display: "block",
                border: "none",
                height: open || hover ? "auto" : 0,
                overflow: "hidden",
                transition: theme.transitions.create(["height"], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                  delay: 0.2,
                }),
              }}
            >
              <Typography
                sx={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "#fff",
                  my: 2,
                  textWrap: "no-wrap",
                  px: 2,
                }}
                textAlign="center"
                color={"#fff"}
              >
                Original Software
              </Typography>
            </ListItem>
          }

          {dataSlidbar.map((item, index) => {
            return (
              <SlidbarItem
                key={index}
                index={index}
                open={open || hover}
                openDrop={openDrop}
                setOpenDrip={setOpenDrip}
                text={item.text}
                path={item.path}
                child={item.child}
                icon={item.icon}
              />
            );
          })}
          <ListItem
            disablePadding
            sx={{
              display: "block",
              width: "calc(100% - 1rem)",
              m: "0.5rem",
              borderRadius: "10px",
              backgroundColor: "transparent",
              overflow: "hidden",
              boxShadow: "1px 4px 10px rgba(0,0,0,.1)",
              transition: "all 0.3s",
              ".textHover": {
                transition: "all 0.3s ",
              },
              "&:hover .textHover": {
                color: "#fff",
              },
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open || hover ? "initial" : "center",
                px: 2.5,
                svg: {
                  fill: "#a7acb1",
                },
                "&:hover": {
                  svg: {
                    fill: "#fff",
                  },
                },
              }}
              onClick={() => {
                setData((old) => ({
                  ...old,
                  filters: {
                    ...old.filters,
                    search: "",
                  },
                }));
                Cookies.remove("token");
                setUserInfoData(null);
                setTimeout(() => {
                  navigate("/login");
                }, 500);
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open || hover ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PowerSettingsNewIcon className="log-out" />
              </ListItemIcon>
              {open || hover ? (
                <ListItemText
                  primary={"Log Out"}
                  className="textHover"
                  sx={{ opacity: open || hover ? 1 : 0, color: "#a7acb1" }}
                />
              ) : (
                <></>
              )}
            </ListItemButton>
          </ListItem>

          <ListItem
            sx={{
              height: 10,
            }}
          ></ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default Slider;
