import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userInfo } from "./recoil";
import LoadingCircle from "./components/Others/LoadingCircle";
import { Layout } from "./layout";
import { Box } from "@mui/material";
import { wholesaleTypesState } from "./recoil/global";

function App() {
  axios.defaults.baseURL = "https://api.original-software.project1.company";
  if (Cookies.get("token")) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + Cookies.get("token");
  }
  const setUserInfoData = useSetRecoilState(userInfo);
  const [wholesaleTypes, setWholesaleTypes] =
    useRecoilState(wholesaleTypesState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`/auth/user/`)
      .then((res) => {
        console.log(res.data);
        setUserInfoData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);



  return (
    <div className="App">
      {loading ? (
        <Box sx={{ mt: "47vh" }}>
          <LoadingCircle />
        </Box>
      ) : (
        <>
          <Layout />
          <ToastContainer />
        </>
      )}
    </div>
  );
}

export default App;
