import React from "react";
import LoginForm from "../components/Auth/LoginForm";
import { Box } from "@mui/material";

const Login = () => {
  return (
    <Box>
      <LoginForm />
    </Box>
  );
};

export default Login;
