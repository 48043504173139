import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Checkbox, FormControlLabel } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

const MultiWholesale = ({ wholesalePrices, setWholesalePrices }) => {
  const handleChange = (id, value) => {
    const list = wholesalePrices.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          prices: value,
        };
      }
      return item;
    });
    setWholesalePrices(list);
  };

  return (
    <Box
      sx={{
        mb: 3,
      }}
    >
      <Grid container>
        {wholesalePrices
          .filter((item) => item.state !== "deleted" && item.state !== "null")
          .map((item, index) => (
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
              item
              xs={12}
              sm={6}
              key={item.id}
            >
              <Grid sm={12} xs={12}>
                <Box
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mt: 2,
                  }}
                  noValidate
                >
                  <TextField
                    fullWidth
                    margin="small"
                    size="small"
                    type="number"
                    id="outlined-basic"
                    label={item?.title}
                    value={item?.prices}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      handleChange(item?.id, e.target.value);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default MultiWholesale;
