import { useEffect } from "react";
import { Avatar, Box, CircularProgress, Link } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
  openModalShow,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import {
  selectedCreatedFromDateState,
  selectedCreatedToDateState,
  selectedIsViewState,
  selectedPaymentMethodState,
  selectedPaymentStatus,
  selectedStatusState,
} from "../../recoil/select";
import { GetUserByIdData } from "../../services/api/user";
import { useLocation } from "react-router-dom";
import ModalInfo from "./Modal/ModalInfo";
import { toast } from "react-toastify";
import UserInfo from "./UserInfo";

export const Tabel = () => {
  const [data, setData] = useState(dataTabelState);
  const [emailSearch, setEmailSearch] = useState(null);
  const [phoneSearch, setPhoneSearch] = useState(null);

  const [selectedStatus, setSelectedStatus] =
    useRecoilState(selectedStatusState);
  const [updateData, setUpdateData] = useRecoilState(updateDataState);
  const state = useRecoilValue(openFilterState);
  const selectedPaymentMethod = useRecoilValue(selectedPaymentMethodState);
  const paymentStatus = useRecoilValue(selectedPaymentStatus);
  const selectedCreatedFromDate = useRecoilValue(selectedCreatedFromDateState);
  const selectedCreatedToDate = useRecoilValue(selectedCreatedToDateState);
  const isView = useRecoilValue(selectedIsViewState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenShowModal = useSetRecoilState(openModalShow);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id"); // Gets the value of 'id'

  function formatDate(inputDate) {
    let formattedDate = `${inputDate.split("T")[0]}`;
    return formattedDate;
  }
  function formatHours(inputDate) {
    let date = new Date(inputDate);
    let hours = date.getHours();
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    let formattedDate = `${hours}:${minutes} ${period}`;
    return formattedDate;
  }

  // replace _ with space and capitalize first letter of each word

  useEffect(() => {
    setDataItem(null);
  }, [state]);

  const fetchData = () => {
    GetUserByIdData(id)
      .then((res) => {
        console.log("Dtest", res);

        setData({ data: res, loading: false });
      })
      .catch(() => {
        setData({ ...data, data: [], loading: false });
        toast.error("order not found");
      });
  };

  useEffect(() => {
    fetchData();
  }, [updateData]);

  return (
    <>
      <Box
        sx={{
          m: 0,
        }}
      >
        <HeaderGridTabel
          title="User Detailes"
          icon={
            <HomeWorkIcon
              size={25}
              className="color-primary"
              color={"#ffffff"}
            />
          }
          href={"/users"}
          hrefText="Users"
          fetchData={fetchData}
          noSearch={true}
          data={data?.data}
          noBorder={true}
        />

        {data?.loading && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {!data?.loading && <UserInfo data={data?.data} />}

        {/* Modal */}
        <ModalInfo />
        <ModalDelete />
      </Box>
    </>
  );
};

export default Tabel;
