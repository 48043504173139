import React, { useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalDelete } from "../../../recoil/modal";
import { dataClickItem, updateDataState } from "../../../recoil/global";
import GeneralModal from "../../GeneralModal/GeneralModal";
import { DeleteCompany } from "../../../services/api/company";
import axios from "axios";

export default function ModalDelete(props) {
  const [loading, setLoading] = useState(false);
  const dataItem = useRecoilValue(dataClickItem);
  const setUpdateData = useSetRecoilState(updateDataState);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   DeleteCompany(dataItem?.id)
  //     .then((res) => {
  //       console.log(res);
  //       toast.success("item deleted successfully");
  //       handleClose();
  //       setUpdateData(Math.random());
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       toast.error(<div>Error cannot delete</div>);
  //       setLoading(false);
  //     });
  // };

  const handleDelete = (event) => {
    event.preventDefault();
    const data = props?.selected?.map((item) => item.id);
    axios
      .delete(props?.bulkDeleteUrl, { data: { ids: data } })
      .then((res) => {
        console.log("res", res);
        toast.success(props?.deleteToastMessage || "Delete Success");
        setUpdateData(Math.random());
        props?.setOpen(false);
        setUpdateData(Math.random());
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Delete Failed");
      });
  };

  return (
    <GeneralModal
      maxWidth={"sm"}
      open={props?.open}
      loading={loading}
      onSubmit={handleDelete}
      onClose={() => props?.setOpen(false)}
      title={props?.title || "Delete"}
    >
      <Box sx={{ mx: 2, my: 1 }}>
        <Typography variant="h2" fontSize={18} fontWeight={400}>
          Do you want to delete all
          <Typography
            fontSize={18}
            fontWeight={400}
            sx={{
              color: "#00c1fb !important",
            }}
            display={"inline"}
          >
            {" "}
            {props?.selected?.length || ""}
          </Typography>{" "}
          items?
        </Typography>
      </Box>
    </GeneralModal>
  );
}
