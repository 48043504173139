import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  LinearProgress,
  Box,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useRecoilState, useSetRecoilState } from "recoil";
import { updateDataState } from "../../recoil/global";
import { GridPagination } from "@mui/x-data-grid";
import { NoData } from "../Others/NoData";
import { display } from "@mui/system";
import { selectedTableItemState } from "../../recoil/select";
import { Button } from "@mui/material";

export const DataGridTabel = (props) => {
  const setUpdateData = useSetRecoilState(updateDataState);
  const [selected, setSelected] = useRecoilState(selectedTableItemState);

  const borders_shadow = {
    borderRadius: "0px",
    boxShadow:
      "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
  };

  useEffect(() => {
    // Select all elements with the data-field="__check__" attribute
    const elements = document.querySelectorAll('[data-field="__check__"]');

    // Add the 'border-0' class to each element
    elements.forEach((element) => {
      element.classList.add("border-0");
    });
  }, []);

  useEffect(() => {
    console.log("props.data", props.data);
    console.log("props.data.filters", props.data.filters);
    console.log("selected", selected);
  }, [props, selected]);

  return (
    <Card
      sx={{
        margin: 0,
        padding: 0,
        borderBottom: !props?.noBorder && "1px solid #ffffff5e",
        pb: 2,
        background: "transparent",
        overflowX: "auto",
        ...borders_shadow,
      }}
      className="card-data-grid"
    >
      <CardContent
        sx={{
          borderRadius: props?.small || !props?.padding ? "0.375rem" : "0px",
          overflow: props?.small || !props?.padding ? "auto" : "auto",
          mx: props.small || !props?.padding ? "24px" : "0px",
        }}
      >
        <DataGrid
          sx={{
            height: props.height ? props.height : "80vh",
            width: "100%",
            minWidth: props?.noMinWidth ? "100px" : "1350px",
            margin: "0px",
            padding: 0,
            border: "none",

            borderRadius: props.small || !props?.padding ? "0.375rem" : "0px",
            background: "#080d2a",
            "& .MuiDataGrid-columnHeaderCheckbox": {
              border: "none !important",
            },

            "& .MuiDataGrid-iconButtonContainer": {
              "& .MuiIconButton-sizeSmall": {
                display: "none",
                opacity: 1,
                svg: {
                  opacity: 1,
                  color: "#fff",
                  path: {
                    fill: "#fff",
                    color: "#fff",
                  },
                },
              },
            },
            "& .MuiDataGrid-columnHeader": {
              borderLeft: "1px solid #ffffff26",

              "& .MuiDataGrid-menuIcon": {
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                "& :hover": {
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                },
                width: "12px",
                marginLeft: "-10px",
                padding: "0px",
                button: {
                  padding: "0px",
                  margin: "0px",
                  border: "none",
                },
              },
            },
            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#08001e",
              color: "#fff",
              borderRadius: "0px",
              border: "none",
            },
            "& .grid--header": {
              backgroundColor: "#08001e",
              color: "#fff",
            },
            "& .MuiDataGrid-menuIcon": {
              // display: "none",
              overflow: "hidden",
              "&:hover": {
                "& .MuiDataGrid-menuIconButton": {
                  visibility: "visible",
                },
              },
            },
            "& .MuiDataGrid-menuIconButton": {
              backgroundColor: "transparent",
              visibility: "visible",
              transition: "all 0.3s",
              border: "5px solid #08001e",
              svg: {
                color: "#fff",
                path: {
                  fill: "#fff",
                },
              },
            },

            "& .MuiDataGrid-footerContainer": {
              display: props?.footerShow ? "none" : "block",
            },
            "& .MuiDataGrid-footerContainer": {
              display: "none", // Hide default footer
            },
            "& .MuiDataGrid-cell": {
              border: "none",
            },
            "& .MuiDataGrid-row": {
              border: "none",
              "&:nth-of-type(odd)": {
                backgroundColor: "#151934", // Red background for odd rows
                color: "#fff",
              },
              "&:nth-of-type(even)": {
                background: "#080d2a", // Green background for even rows
                color: "#fff",
              },
            },
            "& .Mui-selected": {
              border: "none",
              "&:nth-of-type(odd)": {
                backgroundColor: "#151934", // Red background for odd rows
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#0f1535 !important",
                },
              },
              "&:nth-of-type(even)": {
                background: "#080d2a", // Green background for even rows
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#0f1535 !important",
                },
              },
            },
            "&.MuiDataGrid-cellCheckbox": {
              border: "none",
            },
            "& .MuiDataGrid-checkboxInput": {
              // change the box style of the checkbox when not checked
              color: "#fff", // Default checkbox color
              "&:hover": {
                color: "#0d6efd", // Hover color
              },
              "&.Mui-checked": {
                color: "#0d6efd", // Checked color
              },
            },
            "& .MuiDataGrid-cell--withRenderer": {
              borderLeft: "1px solid #ffffff26",

              h6: {
                fontSize: props?.small ? "14px" : "16px",
                fontWeight: props?.small ? "500" : "600",
                color: props?.small ? "#eee" : "#fff",
              },
            },
            ...borders_shadow,
          }}
          components={{
            Header: () => (
              <>
                {!props?.noPagination && (
                  <Box
                    sx={{ display: "flex", justifyContent: "start", gap: 3 }}
                  >
                    <Box
                      sx={{
                        textAlign: "center",
                        padding: "10px",
                        background: "#060b28",
                        display: "flex",
                        justifyContent: "start",
                        background: "#060b28",
                        "& .MuiTablePagination-toolbar": {
                          padding: "0px",
                        },
                      }}
                    >
                      <Typography color={"#fff"} alignContent={"center"}>
                        Show
                      </Typography>
                      <GridPagination
                        sx={{
                          "& .MuiTablePagination-displayedRows ": {
                            display: "none",
                          },
                          "& .MuiTablePagination-actions ": {
                            display: "none",
                          },
                          "& .MuiTablePagination-input": {
                            background: "#0f1535",
                            color: "#fff",
                            border: "1px solid #ffffff26",
                            borderRadius: "0.25rem",
                            overflow: "hidden",
                          },
                          "& .MuiTablePagination-select": {
                            background: "#0f1535",
                            color: "#fff",
                            paddingLeft: "12px !important",
                            paddingRight: "12px !important",
                          },
                          svg: {
                            display: "none",
                          },
                          "& .MuiTablePagination-selectLabel": {
                            display: "none",
                          },
                        }}
                      />
                      <Typography
                        color={"#fff"}
                        alignContent={"center"}
                        ml={"-25px"}
                      >
                        entries
                      </Typography>
                    </Box>
                    {props?.exports && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <Button
                          sx={{
                            textAlign: "center",
                            padding: "10px",
                            display: "flex",
                            justifyContent: "center",
                            background: "transparent",
                            color: "#6c757d",
                            border: "1px solid #6c757d",
                            my: "auto",
                            height: "40px",
                            borderRadius: "5px 0px 0px 5px",
                            "&:hover": {
                              background: "#6c757d",
                              color: "#fff",
                            },
                          }}
                          onClick={props?.copy}
                        >
                          Copy
                        </Button>
                        <Button
                          sx={{
                            textAlign: "center",
                            padding: "10px",
                            display: "flex",
                            justifyContent: "center",
                            background: "transparent",
                            color: "#6c757d",
                            border: "1px solid #6c757d",
                            my: "auto",
                            height: "40px",
                            borderRadius: "0px",
                            "&:hover": {
                              background: "#6c757d",
                              color: "#fff",
                            },
                          }}
                          onClick={props?.exportToExcel}
                        >
                          Excel
                        </Button>
                        <Button
                          sx={{
                            textAlign: "center",
                            padding: "10px",
                            display: "flex",
                            justifyContent: "center",
                            background: "transparent",
                            color: "#6c757d",
                            border: "1px solid #6c757d",
                            my: "auto",
                            height: "40px",
                            borderRadius: "0px",
                            "&:hover": {
                              background: "#6c757d",
                              color: "#fff",
                            },
                          }}
                          onClick={props?.exportToPDF}
                        >
                          PDF
                        </Button>
                        <Button
                          sx={{
                            textAlign: "center",
                            padding: "10px",
                            display: "flex",
                            justifyContent: "center",
                            background: "transparent",
                            color: "#6c757d",
                            border: "1px solid #6c757d",
                            my: "auto",
                            height: "40px",
                            borderRadius: "0px 5px 5px 0px",
                            "&:hover": {
                              background: "#6c757d",
                              color: "#fff",
                            },
                          }}
                          onClick={props?.print}
                        >
                          Print
                        </Button>
                      </Box>
                    )}
                  </Box>
                )}
              </>
            ),
            Footer: () => (
              <>
                {!props.noPagination && (
                  <Box
                    sx={{
                      textAlign: "center",
                      padding: "10px",
                      display: "flex",
                      justifyContent: "start",
                      background: "#060b28",
                      "& .MuiTablePagination-toolbar": {
                        padding: "0px",
                      },
                      "& .MuiTablePagination-root": {
                        width: "100%",
                      },
                    }}
                  >
                    <GridPagination
                      sx={{
                        "& .MuiTablePagination-input": {
                          background: "#0f1535",
                          color: "#fff",
                          border: "1px solid #ffffff26",
                          borderRadius: "0.25rem",
                          overflow: "hidden",
                          display: "none",
                        },
                        "& .MuiTablePagination-select": {
                          background: "#0f1535",
                          color: "#fff",
                          paddingLeft: "12px !important",
                          paddingRight: "12px !important",
                        },
                        "& button:disabled": {
                          svg: {
                            fill: "#635e71",
                          },
                        },

                        "& .MuiTablePagination-selectLabel": {
                          display: "none",
                        },
                        p: {
                          color: "#fff",
                        },
                      }}
                    />
                  </Box>
                )}
                {props?.footer}
              </>
            ),
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: NoData,
          }}
          checkboxSelection={props?.checkbox}
          disableRowSelectionOnClick
          onSelectionModelChange={(ids) => {
            const selectedRows = props?.data.data.filter((row) =>
              ids.includes(row.id)
            );
            setSelected(selectedRows); // Store full row data
          }}
          selectionModel={props.selectionModel}
          pageSize={props.data.filters.take ? props.data.filters.take : 25}
          rowCount={props.data.total ? props.data.total : 0}
          rows={props.data.data ? props.data.data : []}
          loading={props.data.loading}
          page={props.data.filters.page}
          columns={props.columns}
          rowHeight={
            props?.cellHeight ? props.cellHeight : props?.small ? 40 : 65
          }
          pagination={!props?.noPagination}
          paginationMode="server"
          onPageSizeChange={(newPageSize) => {
            props.setData((old) => ({
              ...old,
              filters: {
                ...old.filters,
                take: newPageSize,
                skip: 0,
                page: 0,
              },
            }));
            setUpdateData(Math.random());
          }}
          onPageChange={(newPage) => {
            props.setData((old) => ({
              ...old,
              filters: {
                ...old.filters,
                skip: newPage + 1,
                page: newPage,
              },
            }));
            setUpdateData(Math.random());
          }}
        />
      </CardContent>
    </Card>
  );
};
